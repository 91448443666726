export default class EmailDecoder {
  at: string;
  dot: string;

  constructor(at = 'at', dot = 'dot') {

    this.at = at;
    this.dot = dot;
  }

  replaceAllOn(element: HTMLElement, selector: string) {
    const elements = document.querySelectorAll(selector);
    Array.prototype.forEach.call(elements, this.replaceElementContent.bind(this));
  }

  replaceElementContent(element: HTMLElement) {
    element.innerHTML = this.replace(element.innerHTML);
  }

  replace(input: string) {
    const email = this.removePlaceholders(input);
    return this.enclusureWithATag(email);
  }

  enclusureWithATag(email: string) {
    return '<a href="mailto:' + email + '">' + email + '</a>';
  }

  removePlaceholders(input: string) {
    return input.replace(this.getAtReplacement(), "@")
      .replace(this.getDotReplacement(), ".");
  }

  getAtReplacement() {
    return this.parenthese(this.at);
  }

  getDotReplacement() {
    return this.parenthese(this.dot);
  }

  parenthese(input:string) {
    return "(" + input + ")";
  }
}
