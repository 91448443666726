export default class ShowMoreHandler {
  private buttonSelector: string;
  private contentSelector: string;
  private containerLevel: number;

  constructor(buttonSelector = '.show-more', contentSelector = '.other-content', containerLevel = 1) {
    this.buttonSelector = buttonSelector;
    this.contentSelector = contentSelector;
    this.containerLevel = containerLevel;

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  attachListener(element: HTMLElement) {
    element.addEventListener('click', this.onClickHandler);
  }

  detachListener(element: HTMLElement) {
    element.removeEventListener('click', this.onClickHandler);
  }

  private findContainer(element: HTMLElement) {
    let container = element;
    let level = this.containerLevel;

    while (level-- > 0) {
      if (container.parentNode) {
        container = <HTMLElement> container.parentNode;
      }
    }


    return container;
  }

  private findOtherContent_(element: HTMLElement) {
    let parentElement = <HTMLElement> element.parentNode;
    return <HTMLElement> parentElement.querySelector(this.contentSelector);
  }

  onClickHandler(event: MouseEvent) {
    let targetElement = <HTMLElement> event.target;
    if (!targetElement || !targetElement.matches(this.buttonSelector)) {
      return;
    }

    const buttonContainer = this.findContainer(targetElement);
    this.hide(buttonContainer);
    const otherContent = this.findOtherContent_(buttonContainer);
    this.show(otherContent);

  }

  private hide(element: HTMLElement) {
    if (element) {
      element.style.display = 'none';
    }
  }

  private show(element: HTMLElement) {
    if (element) {
      element.style.display = 'block';
    }
  }


};
