import {ready} from '../../../common/scripts/utils'
import init from '../../../common/scripts/init'

import Tabs from './tabs';
import TabBlocks from './tabBlocks';

ready(init);

function initTabs() {
  if (elementExists('trainings-tabs')) {
    const tabs = new Tabs('trainings-tabs');

    if (elementExists('trainings-blocks')) {
      const tabBlocks = new TabBlocks('trainings-blocks', tabs);
    }
  }
}

function elementExists(elementId : string) {
  return !!document.getElementById(elementId);
}

ready(initTabs);
