import ContactForm from "./contact-form"
import EmailDecoder from "./email-decoder"
import ShowMoreHandler from "./show-more-handler"
import HamburgerMenu from "./hamburger-menu"
import PositionWatcher from "./position-watcher"
import SmoothScroll from "./smooth-scroll"
import PublicTrainingExpirator from "./public-training-expirator"
import Menu from "./menu"
import Zippy from "./zippy";

export default function init() {

  var body = document.body;

  var expirator = new PublicTrainingExpirator(document.body, '#public-terms');
  expirator.hideExpired();

  var decoder = new EmailDecoder("zavináč", "tečka");
  decoder.replaceAllOn(body, '.email-replace');

  var hamburgerMenu = new HamburgerMenu(document.body, '#top-menu .menu-button', '#top-menu .menu-list');

  var showMore = new ShowMoreHandler();
  showMore.attachListener(body);

  var setElementIdAsLocationHash = function (element: Element) {
    var id = element.id;
    if (id) {
      // TODO support pro IE9?
      history.replaceState({}, '', '#' + id);
    }
  };

  var positionWatcher = new PositionWatcher(document, '.main-section', 40);
  positionWatcher.registerListener(setElementIdAsLocationHash);

  let menuElement = <HTMLElement> document.querySelector('.side-bar .menu-list');
  var menu = new Menu(menuElement);
  positionWatcher.registerListener(function (newSection) {
    menu.activateByHref('#' + newSection.id);
  });

  new SmoothScroll(document.body, '#top-menu .menu-button');

  let form = document.querySelector('#contactForm') as HTMLFormElement;
  if (form) {
    new ContactForm(form);
  }

  new Zippy(document.body);
}
