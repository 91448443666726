// inspired by https://github.com/Yappli/smooth-scroll

export default class SmoothScroll {

  private root: HTMLElement;
  private linkSelector: string;
  private headerSelector: string;
  private movingFrequency: number;
  private hopCount: number;


  constructor(element: HTMLElement, headerSelector: string, speed = 300, movingFrequency = 15, linkSelector = 'a') {
    this.root = element;
    this.headerSelector = headerSelector;

    this.hopCount = (speed - (speed % movingFrequency)) / movingFrequency;
    this.movingFrequency = movingFrequency;

    this.linkSelector = linkSelector;


    this.onClick = this.onClick.bind(this);

    element.addEventListener('click', this.onClick);
  }

  onClick(event: MouseEvent) {
    const target = <HTMLElement>event.target;
    if (!target.matches(this.linkSelector)) {
      return
    }

    const href = target.getAttribute('href') || '';
    if (!(href.substr(0, 1) === '#')) {
      return
    }

    const sectionId = href.substr(href.indexOf('#') + 1);
    const section = document.getElementById(sectionId);
    if (section) {
      const getScrollTopDocumentAtBegin = this.getScrollTopDocument();
      const gap = (this.getScrollTopElement(section) - getScrollTopDocumentAtBegin) / this.hopCount;

      if (window.history && typeof window.history.replaceState === 'function') {
        window.history.replaceState({}, undefined, '#' + sectionId);
      }

      for (let i = 1; i <= this.hopCount; i++) {
        let hop_top_position = gap * i;
        setTimeout(() => {
          window.scrollTo(0, hop_top_position + getScrollTopDocumentAtBegin);
        }, this.movingFrequency * i);
      }

      event.stopPropagation();
      event.preventDefault();

    }

  }


  getScrollTopElement(element: HTMLElement) {
    const height_fixed_header = this.getHeaderOffset();

    let top = height_fixed_header * -1;

    while (element.offsetParent != undefined && element.offsetParent != null) {
      top += element.offsetTop + (element.clientTop != null ? element.clientTop : 0);
      element = <HTMLElement>element.offsetParent;
    }

    return top;
  }

  getHeaderOffset(): number {
    const element = document.querySelector(this.headerSelector);
    const styles = window.getComputedStyle(element);
    const height = parseInt(styles.height || '');

    return height ? height : 0;
  }

  getScrollTopDocument() {
    return window.pageYOffset !== undefined ?
      window.pageYOffset :
      document.documentElement.scrollTop !== undefined ? document.documentElement.scrollTop : document.body.scrollTop;
  }
}
