
export default class HamburgerMenu {
  buttonSelector: string;
  menuSelector: string;
  rootElement: HTMLElement;

  constructor(rootElement: HTMLElement, buttonSelector: string, menuSelector: string) {
    this.buttonSelector = buttonSelector;
    this.menuSelector = menuSelector;
    this.rootElement = rootElement;

    this.menuClickListener = this.menuClickListener.bind(this);
    this.bodyClickListener = this.bodyClickListener.bind(this);

    const button: Element = document.querySelector(this.buttonSelector);
    button.addEventListener('click', this.menuClickListener);
  }

  bodyClickListener() {
    const menu = this.getMenu();
    if (menu) {
      menu.style.display = 'none';
    }
    this.rootElement.removeEventListener('click', this.bodyClickListener);
  }

  menuClickListener(event: MouseEvent) {
    event.stopPropagation();

    let menu = this.getMenu();
    if (!menu) {
      return
    }
    menu.style.display = 'block';
    this.rootElement.addEventListener('click', this.bodyClickListener)
  }

  private getMenu(): HTMLElement {
    return <HTMLElement>this.rootElement.querySelector(this.menuSelector);
  }

}
