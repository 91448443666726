export default class Zippy {

  constructor(private rootElement: HTMLElement) {
    this.rootElement = rootElement;

    const zippyNodeList = this.rootElement.querySelectorAll('[data-zippy]');
    const zippies = Array.prototype.slice.call(zippyNodeList);

    zippies.forEach((zippy: HTMLElement) => zippy.addEventListener('click', Zippy.clickListener));
  }

  static clickListener(event: MouseEvent) {
    const target: HTMLElement = <HTMLElement>event.target;

    const zippyId = target.dataset['zippy'];
    const zippyElement = document.getElementById(zippyId);

    if (zippyElement) {
      zippyElement.classList.remove('is-hidden');
    }
  }
}
