export default class Menu {
  private element: HTMLElement;
  private itemSelector: string;
  private activeClass: string;

  constructor(element: HTMLElement, itemSelector = 'a', activeClass = 'is-active') {
    this.element = element;
    this.itemSelector = itemSelector;
    this.activeClass = activeClass;
  }

  deactivateActive() {
    const activeElement = this.element.querySelector('.' + this.activeClass);
    if (activeElement) {
      activeElement.classList.remove(this.activeClass);
    }
  }

  activate_(item: HTMLElement) {
    item.classList.add(this.activeClass);
  }

  activateByHref(href: string) {
    const item = <HTMLElement>this.element.querySelector(this.itemSelector + '[href="' + href + '"]');
    if (item) {
      this.deactivateActive();
      this.activate_(item);
    }
  }

};

