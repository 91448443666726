interface Position {
  top: number,
  bottom: number
}

export default class PositionWatcher {

  private root: Document;
  private sectionSelector: string;
  private distance: number;

  private lastSectionIndex: number = -1;
  private listeners: Array<(element: HTMLElement) => void> = [];

  constructor(document: Document, sectionSelector = '.main-section', distance = 40) {
    this.root = document;
    this.sectionSelector = sectionSelector;
    this.distance = distance;


    this.onScrollHandler = this.onScrollHandler.bind(this);
    this.sectionToTopPosition = this.sectionToTopPosition.bind(this);
    this.isSectionBeforeDistance = this.isSectionBeforeDistance.bind(this);

    document.addEventListener('scroll', this.onScrollHandler);

  }

  triggerInitial() {
    this.onScrollHandler({} as MouseEvent);
  }

  notifyListeners(currentSection: HTMLElement) {
    this.listeners.map(function (f) {
      try {
        f(currentSection);
      } catch (e) {
        console.error('PositionWatcher: Listener error', e);
      }
    })
  }

  private sectionToTopPosition(element: HTMLElement): Position {
    const rect = element.getBoundingClientRect();

    return {
      top: rect.top,
      bottom: rect.bottom
    }
  }

  private isSectionBeforeDistance(position: Position) {
    return position.top < this.distance;
  }

  private onScrollHandler(event: MouseEvent) {
    const mainSections = this.root.querySelectorAll(this.sectionSelector);
    const sectionTops = Array.prototype.map.call(mainSections, this.sectionToTopPosition);
    const sectionBefore = sectionTops.filter(this.isSectionBeforeDistance);

    const currentSectionIndex = Math.max(sectionBefore.length - 1, 0);
    if (this.lastSectionIndex != currentSectionIndex) {
      this.lastSectionIndex = currentSectionIndex;
      const currentSection = <HTMLElement>mainSections[currentSectionIndex];

      this.notifyListeners(currentSection);
    }
  }

  registerListener(callback: (element: HTMLElement) => void) {
    this.listeners.push(callback)
  }
};

