export default class Tabs {

  private tabs = [];
  private tabElement: HTMLElement;

  constructor(private id: string) {
    this.tabElement = <HTMLElement>document.getElementById(id);

    if (document.location.hash.startsWith('tab-')) {

    }

    const tabNodeList = <NodeList>this.tabElement.querySelectorAll('.tab');
    this.tabs = Array.prototype.slice.call(tabNodeList);

    this.tabs.forEach((tab: HTMLElement) => {
      tab.addEventListener('click', () => this.select(tab));
    });

    this.hideAllTabs();
    this.select(this.tabs[0]);
  }

  show() {
    this.tabElement.classList.remove('is-hidden');
  }

  hideAllTabs() {
    this.tabs.forEach(this.hideTab);
  }

  hideTab(tab: HTMLElement) {
    tab.classList.remove('is-active');
    const tabId = tab.dataset['tab'];

    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.classList.add('is-hidden');
    }
  }

  select(tab: HTMLElement) {
    this.hideAllTabs();
    tab.classList.add('is-active');
    const tabId = tab.dataset['tab'];

    const tabElement = document.getElementById(tabId);
    if (tabElement) {
      tabElement.classList.remove('is-hidden');
    }
  }

  selectTabId(tabId: string) {
    for (let index = 0; index < this.tabs.length; index++) {
      const tab: HTMLElement = this.tabs[index];

      if (tab.dataset['tab'] === tabId) {
        this.select(tab);

        break;
      }
    }
  }
}
