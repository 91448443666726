export default class ContactForm {
  private formElement: HTMLFormElement;
  private messageId: string;

  constructor(formElement: HTMLFormElement, messageId = '__internal-form-message-id__') {
    this.formElement = formElement;
    this.messageId = messageId;

    this.onSubmit = this.onSubmit.bind(this);

    this.addListener();
  }

  public onSubmit(event: Event) {
    event.preventDefault();

    let data = new FormData(this.formElement);  // TODO asi jen IE10+
    data.append('contactForm-antispam', 'ang-ular-mail-form');

    this.send(this.formElement.action, this.formElement.method, data);
  }

  private send(url: string, method:string, data: {}) {
    const request = new XMLHttpRequest();
    request.open(method, url, true);

    let onLoad = function() {
      if (request.status >= 200 && request.status < 400) {
        this.onSuccess();
      } else {
        this.onError();
      }
    }.bind(this);

    request.onload = onLoad;

    request.onerror = function() {
      this.onError();
    }.bind(this);

    request.send(data);
  }

  private addListener() {
    this.formElement.addEventListener("submit", this.onSubmit)
  }

  private getMessageElement() {
    return document.querySelector('#' + this.messageId);
  }

  public onSuccess() {
    let message = `<p id="form-message" class="notification is-primary">
  <b>Formulář byl odeslán.</b><br />
  Děkujeme za Váš zájem. Odpovíme Vám, hned jak to bude možné.
 </p>`;

    this.showMessage(message);
    this.formElement.classList.add('is-hidden');
  }

  public onError() {
    let message = `<p id="form-message" class="notification is-danger">
  <b>Došlo k chybě při odesílání formuláře</b>.
  Napiště nám přímo na <a href="mailto:angular@angular.cz">angular@angular.cz</a>
  </p>`;

    this.showMessage(message);
  }

  private clearMessage() {
    let element = this.getMessageElement();
    if (element) {
      element.parentNode.removeChild(element);
    }
  }

  private showMessage(message: string) {
    this.clearMessage();
    this.formElement.insertAdjacentHTML('beforebegin', message);
  }


}
