

import Tabs from "./tabs";

export default class TabBlocks{
  private blocksElement: HTMLElement;

  constructor(id : string, tabs : Tabs) {
    this.blocksElement = <HTMLElement>document.getElementById(id);

    tabs.hideAllTabs();

    const linksNodeList = this.blocksElement.querySelectorAll('a.action-button');
    const links = Array.prototype.slice.call(linksNodeList);

    links.forEach((block:HTMLElement) => {

      const tabId = block.dataset['tab'];
      block.addEventListener("click", () => {
        tabs.selectTabId(tabId);
        tabs.show();
        this.hide();

        location.hash = '#skoleni-pro-firmy';
      });
    });
  };

  hide() {
    this.blocksElement.classList.add('is-hidden');
  }
}
