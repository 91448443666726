export default class PublicTrainingExpirator {
  private expiringElements: Element[];
  private listElement: Element;
  private noTermElement: Element;

  constructor(private rootElement: HTMLElement, termsSelector: string) {
    const elements = this.rootElement.querySelectorAll('[data-expires]');
    this.expiringElements = Array.prototype.slice.call(elements);

    this.listElement = this.rootElement.querySelector(`${termsSelector}-list`) as HTMLElement;
    this.noTermElement = this.rootElement.querySelector(`${termsSelector}-no-term`) as HTMLElement;

  }

  hideExpired() {
    function isExpired(element: HTMLElement) {

      const expireDate = new Date(element.getAttribute('data-expires'));
      return expireDate < new Date();
    }

    const expiredTrainings = this.expiringElements
      .filter(isExpired);

    expiredTrainings.forEach(element => element.setAttribute('hidden', ''));

    const numberOfNotExpiredTrainings = this.expiringElements.length - expiredTrainings.length;

    if (numberOfNotExpiredTrainings) {
      this.listElement.removeAttribute('hidden');
      this.noTermElement.setAttribute('hidden', '');
    } else {
      this.noTermElement.removeAttribute('hidden');
      this.listElement.setAttribute('hidden', '');
    }

  }
}
